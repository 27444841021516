<template>
  <div class="detail-task">
    <el-row>
      <el-col :span="12">
        <div class="flex flex-nowrap items-center custom-single-picker">
          <span class="mr-[12px] font-semibold">Start Date</span>
          <el-date-picker :disabled-date="disabledDate" v-model="timeSheetHeader.StartDate" type="date" placeholder="Start Date"
            class="h-[40px] w-[180px]" popper-class="popper-single-class" format="DD MMM YYYY" @focus="datePickerfocus"
            ref="datePickerStartDate" :prefix-icon="IconCalendar" :disabled="!allowUpdate" @change="handleCalendarChange"/>
        </div>
        <div class="flex flex-nowrap items-center mt-[8px]" v-if="!timeSheetHeader.StartDate">
          <div class="ml-[80px]"></div>
          <div class="font-medium" style="color: #ed1c2d">
            Please choose start date
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="flex flex-nowrap items-center justify-start">
          <div>
            <div class="flex-1 h-10" align="left">
              <el-button :disabled="!allowUpdate || !timeSheetHeader.Shift" class="btn-time-shift btn-default-2 btn-shift"
                :class="!timeSheetHeader.Shift && 'btn-time-shift-selected'" @click="onConfirmChangeShift('Day')">
                <icon-svg :class="timeSheetHeader.Shift && 'icon-btn-time-shift'" size="24" name="day-shift" />
                <span class="ml-2.5">Day Shift</span>
              </el-button>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="flex flex-nowrap items-center justify-end">
          <div>
            <div class="flex-1 h-10" align="left">
              <el-button :disabled="!allowUpdate || timeSheetHeader.Shift" class="btn-time-shift btn-default-2 btn-shift"
                :class="timeSheetHeader.Shift && 'btn-time-shift-selected'" @click="onConfirmChangeShift('Night')">
                <icon-svg :class="!timeSheetHeader.Shift && 'icon-btn-time-shift'" size="24" name="night-shift" />
                <span class="ml-2.5">Night Shift</span>
              </el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="w-full mt-[20px] mb-[20px] task-divide"></div>
    <div class="mb-[10px]" v-for="(item, index) in timeSheetHeader.TimesheetLines" v-bind:key="item.TaskUuid">
      <el-row class="mb-5">
        <el-col :span="12">
          <div class="h-[32px] justify-center text-xl font-semibold">
            <span>{{ `Task ${index + 1}` }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="flex justify-end">
            <el-button v-if="(timeSheetHeader.TimesheetLines?.length as number) > 1 && (displayButtonAddTask)"
              class="flex h-[32px] w-[32px] items-center justify-center cursor-pointer task-icon-trash"
              @click="removeTask(item.TaskUuid)" :disabled="!allowUpdate">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.0006 2.66666H11.934C11.615 1.11572 10.2507 0.002 8.6673 0H7.33395C5.75055 0.002 4.38623 1.11572 4.0673 2.66666H2.00064C1.63245 2.66666 1.33398 2.96513 1.33398 3.33331C1.33398 3.7015 1.63245 4 2.00064 4H2.6673V12.6667C2.66952 14.5067 4.16061 15.9978 6.00064 16H10.0006C11.8407 15.9978 13.3318 14.5067 13.334 12.6667V4H14.0006C14.3688 4 14.6673 3.70153 14.6673 3.33334C14.6673 2.96516 14.3688 2.66666 14.0006 2.66666ZM7.33398 11.3333C7.33398 11.7015 7.03552 12 6.66733 12C6.29911 12 6.00064 11.7015 6.00064 11.3333V7.33334C6.00064 6.96516 6.29911 6.66669 6.6673 6.66669C7.03548 6.66669 7.33395 6.96516 7.33395 7.33334V11.3333H7.33398ZM10.0006 11.3333C10.0006 11.7015 9.70217 12 9.33398 12C8.9658 12 8.66733 11.7015 8.66733 11.3333V7.33334C8.66733 6.96516 8.9658 6.66669 9.33398 6.66669C9.70217 6.66669 10.0006 6.96516 10.0006 7.33334V11.3333ZM5.44798 2.66666C5.73155 1.86819 6.48667 1.33434 7.33398 1.33331H8.66733C9.51464 1.33434 10.2698 1.86819 10.5533 2.66666H5.44798Z" />
              </svg>
            </el-button>
            <div class="flex h-[32px] w-[32px] items-center justify-center ml-[20px] cursor-pointer task-icon-expand"
              @click="handelExpendTask(item.TaskUuid)">
              <el-icon color="#FFFFFF">
                <ArrowDown v-if="item.Option?.isExpend === false" />
                <ArrowUp v-if="item.Option?.isExpend === true" />
              </el-icon>
            </div>
          </div>
        </el-col>
      </el-row>
      <div :style="{
        display: item.Option?.isExpend === true ? '' : 'none',
      }">
        <div class="flex items-center justify-center">
          <div class="flex flex-row task-type rounded-group-button">
            <div class="flex items-center justify-center job-type cursor-pointer task-type-left"
            v-if="taskTypeDisplay.rental || (isEdit && (item.Option as any)?.type === 'Rental') || !!item.TimesheetLineId" 
              v-on:click=" taskTypeDisplay.rental ? handleOpenPopupChangeTaskType('Rental', item.TaskUuid)  : handleErrorSwitchTaskType()"
              :class="item.Option?.type === 'Rental' ? 'task-type-selected' : ''
                ">
              <span class="font-semibold">Rental</span>
            </div>
            <div class="flex items-center justify-center job-type cursor-pointer task-type-middle"
            v-if="taskTypeDisplay.jobCost || (isEdit && (item.Option as any)?.type === 'JobCost')|| !!item.TimesheetLineId"
              v-on:click="taskTypeDisplay.jobCost ? handleOpenPopupChangeTaskType('JobCost', item.TaskUuid) : handleErrorSwitchTaskType()" 
              :class="item.Option?.type === 'JobCost' ? 'task-type-selected' : ''
                ">
              <span class="font-semibold">Job Cost</span>
            </div>
            <div class="flex items-center justify-center job-type cursor-pointer task-type-right"
            v-if="taskTypeDisplay.unBilled || (isEdit && (item.Option as any)?.type === 'UnBilled') || !!item.TimesheetLineId"
              v-on:click="taskTypeDisplay.unBilled ?handleOpenPopupChangeTaskType('UnBilled', item.TaskUuid): handleErrorSwitchTaskType()" 
              :class="item.Option?.type === 'UnBilled' ? 'task-type-selected' : ''
                ">
              <span class="font-semibold">UnBilled</span>
            </div>
          </div>
        </div>
        <div>
          <el-row class="mt-[20px]">
            <el-col :span="12">
              <div class="flex flex-nowrap items-center">
                <span class="mr-[12px] font-semibold">Start Time</span>
                <TimePicker :additionValues="{
                    TaskUuid: item.TaskUuid,
                    isStartTime: true,
                    endTime: item.Option?.endTime
                  }
                  " :hours="item.Option?.startTime.hours" :minutes="item.Option?.startTime.minutes"
                  :isAm="item.Option?.startTime.isAm" :isInit="item.Option?.startTime.isInit" :disable="!allowUpdate" @submit="updateTaskTime" @changeValidTime="onChangeValidTime"></TimePicker>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="flex flex-nowrap items-center justify-end">
                <span class="mr-[12px] font-semibold">End Time</span>
                <TimePicker :additionValues="{
                    TaskUuid: item.TaskUuid,
                    isEndTime: true,
                    startTime: item.Option?.startTime
                  }
                  " :hours="item.Option?.endTime.hours" :minutes="item.Option?.endTime.minutes"
                  :isAm="item.Option?.endTime.isAm" :isInit="item.Option?.endTime.isInit" :disable="!allowUpdate" @submit="updateTaskTime" @changeValidTime="onChangeValidTime"></TimePicker>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-if="item.Option?.type === 'Rental'" class="custom-input">
          <RentalTask @submit="handleTaskSubmit" :data="{
            index,
            taskId: item.TaskUuid,
            workOrderTaskId: item.WorkOrderTaskId,
            workOrderId: item.WorkOrderId,
            fleetId: item.EquipmentId,
            siteId: item.SiteId,
            jobDescription: item.Notes,
            additionInfo: item.TimesheetAdditional,
            hours: item.Option.hours,
            mins: item.Option.mins,
            timeSheetStatus: timeSheetHeader.TimesheetStatus,
          }" :isEdit="isEdit" :allowUpdate="allowUpdate" :errors="errors" :taskTypeDisplay="taskTypeDisplay" :totalHour="totalHour"
            @updateTimeSheet="handleSubmitTaskTime"
            @changeTouched="(value: any) => onChangeTouched(value, item.TaskUuid)"></RentalTask>
        </div>
        <div v-if="item.Option?.type === 'JobCost'" class="custom-input">
          <JobCostTask @submit="handleTaskSubmit" :data="{
            index,
            taskId: item.TaskUuid,
            jobCostCodeId: item.JobCostCodeId,
            jobId: item.JobId,
            jobDescription: item.Notes,
            additionInfo: item.TimesheetAdditional,
            hours: item.Option.hours,
            mins: item.Option.mins,
            isEmecoSite: item.EmecoSite,
            workOrderId: item.WorkOrderId,
            fleetId: item.EquipmentId,
            siteId: item.SiteId,
            timeSheetStatus: timeSheetHeader.TimesheetStatus,
          }" :isEdit="isEdit" :allowUpdate="allowUpdate" :errors="errors" :taskTypeDisplay="taskTypeDisplay" :totalHour="totalHour"
            @updateTimeSheet="handleSubmitTaskTime" :payLocation="timeSheetHeader.User?.PayLocation ? timeSheetHeader.User?.PayLocation : null"
            @changeTouched="(value: any) => onChangeTouched(value, item.TaskUuid)"></JobCostTask>
        </div>
        <div v-if="item.Option?.type === 'UnBilled'" class="custom-input">
          <UnBilledTask @submit="handleTaskSubmit" :data="{
            index,
            taskId: item.TaskUuid,
            gLAccountId: item.GlaccountId,
            jobDescription: item.Notes,
            additionInfo: item.TimesheetAdditional,
            hours: item.Option.hours,
            mins: item.Option.mins,
            timeSheetStatus: timeSheetHeader.TimesheetStatus,
          }" :isEdit="isEdit" :allowUpdate="allowUpdate" :errors="errors" :taskTypeDisplay="taskTypeDisplay" :totalHour="totalHour"
            @updateTimeSheet="handleSubmitTaskTime"
            @changeTouched="(value: any) => onChangeTouched(value, item.TaskUuid)"></UnBilledTask>
        </div>
      </div>
    </div>
    <div v-if="displayButtonAddTask">
      <div class="flex-1 h-10 mt-[20px]" align="left">
        <el-button class="btn-bg-color-red btn-default-2 btn-add-task" @click="addTask" :disabled="!allowUpdate">
          <icon-svg size="24" name="plus-circle" />
          <span class="ml-2.5">Add Task</span>
        </el-button>
      </div>
    </div>
    <div class="flex items-center justify-center h-[48px] mt-[20px] task-total-border">
      <span class="text-xl font-medium">Total:</span>
      <span style="color: #246bfd" class="ml-[5px] text-xl font-medium">{{
        `${("0" + totalHours).slice(-2)} hrs ${("0" + totalMins).slice(
          -2
        )} mins`
      }}</span>
    </div>
  </div>
  <div class="el-dialog-popup">
    <PopupConfirm :type="'Switching Task'"
      :message="'You are switching to another task. \nThe existing task data will be deleted.'" :lblBtnSubmit="'Yes'"
      :iconPopup="'warring'" :iconBtnName="'check-mark-circle'" @submit="handleSelectTabTask"
      ref="popupConfirmChangeType"></PopupConfirm>
  </div>
  <div class="el-dialog-popup">
    <PopupConfirm :type="'Switching Shift'"
      :message="'You are switching shift flag;\n this might affect the input date.\n Please confirm.'" :lblBtnSubmit="'Yes'" :lblBtnCancel="'No'"
      :iconPopup="'warring'" :iconBtnName="'check-mark-circle'" @submit="onChangeShift(timeSheetHeader.Shift ? 'Day' : 'Night')"
      ref="popupConfirmChangeShift"></PopupConfirm>
  </div>
  <div class="el-dialog-popup">
    <PopupConfirm :type="'Deleting'" :message="'Your data will be deleted. Are you sure?'" :lblBtnSubmit="'Delete'"
      :iconPopup="'icon-popup-delete'" :iconBtnName="'delete'" @submit="handleRemoveTask" ref="popupConfirmDelete">
    </PopupConfirm>
  </div>
  <div class="el-dialog-popup">
    <PopupConfirm :type="'Error'" :message="'You cannot perform this function'" 
      :iconPopup="'close-no-circle-red'" ref="popupError">
    </PopupConfirm>
  </div>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  onBeforeMount,
  ref,
  watch,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import TimePicker from "@/components/TimePicker.vue";
import { ArrowDown, ArrowUp } from "@element-plus/icons-vue";
import {
  MESSAGE_CODES,
  TASK_TYPES,
  USER_TYPE_APPROVER,
  USER_TYPE_SYSTEM_ADMINISTRATOR,
  USER_TYPE_USER_ADMINISTRATOR,
} from "core/constants";
import RentalTask from "@/components/RentalTask.vue";
import JobCostTask from "@/components/JobCostTask.vue";
import UnBilledTask from "@/components/UnBilledTask.vue";
import PopupConfirm from "@/components/PopupConfirm.vue";
import IconSvg from "@/layouts/IconBase.vue";
import IconCalendar from "@/components/IconCalendar.vue";
import { v4 as uuidv4 } from "uuid";
import { convertTaskIdToType, convertTaskIdToVisbleType, formatTime, getTaskTypId } from "../utils";
import dayjs from "dayjs";
import { Actions, timeSheetStatusName } from "modules/dashboard/store/types";
import { TASK_TYPES_VALUE } from "core/constants";
import { CurrentUserModel } from "modules/auth/store/types";
import { showError } from "../utils/notifications";
import { getHoursAndMinutes } from "../utils/time";


export default defineComponent({
  name: "DetailTask",
  props: {
    isEdit: Boolean,
    isTouched: Boolean,
    dataEdit: Object,
    isEndTimePM: Number,
    totalHour: Number,
    shift: Boolean,
    dialogVisible: Boolean,
  },
  components: {
    TimePicker,
    ArrowDown,
    RentalTask,
    JobCostTask,
    UnBilledTask,
    ArrowUp,
    IconSvg,
    IconCalendar,
    PopupConfirm,
  },
  setup(props, ctx) {
    const store = useStore();
    const bgColorTaskType = computed(() =>
      store.state.currentTheme === "dark" ? "#5E6272" : "rgba(35, 31, 32, 0.1)"
    );
    const timeSheetHeader = ref<TimeSheetHeader>({
      Shift: false,
      StartDate: dayjs().format("YYYY-MM-DD"),
      TimesheetLines: [],
    });
    const currentUser = computed<CurrentUserModel>(
      () => store.getters["auth/currentUser"]
    );
    const allFieldIsValid = computed(() => store.getters["auth/allFieldIsValid"])
    const isEdit = ref<Boolean>(props.isEdit);

    watch(props,() => {
      if (!props.dialogVisible) {
        isTouchedSwitch.value = false
        cachingStartTime.value = {
          hours: 8,
          minutes: 30,
          isAm: true, 
          isInit: false, 
        }
      }
    })
    const oldData = ref();
    const isTouched = ref<Record<string, Boolean>>({});
    const popupConfirmChangeType = ref<InstanceType<typeof PopupConfirm>>();
    const popupConfirmDelete = ref<InstanceType<typeof PopupConfirm>>();
    const popupError = ref<InstanceType<typeof PopupConfirm>>();
    const popupConfirmChangeShift = ref<InstanceType<typeof PopupConfirm>>();
    const notify = inject("notify", (type: string, message: string) => ({}));
    const totalHours = ref<number>(0);
    const totalMins = ref<number>(0);
    const currentTaskVisibleType = ref<any>(null);
    const overlapTime = ref<Boolean>(false);
    const messageValidTime = ref('')
    const cachingStartTime = ref<any>({
      hours: 8,
      minutes: 30,
      isAm: true, 
      isInit: false, 
    });
    const errors = ref<any>({
      overlap: {
        id: null,
        message: null
      }
    });
    const disabledDate = (time: Date) => {
      return time.getTime() > Date.now()
    }
    const isTouchedSwitch = ref<boolean>(false);

    const currentTheme = computed(() => store.state.currentTheme);
    const allTaskIsValid = computed(() => {
      const findItemValidFail = timeSheetHeader.value.TimesheetLines?.find(
        (x) => x.Option?.isValid == false
      );
      return findItemValidFail != undefined ? false : true;
    });
    
    const taskTypeDisplay = computed(() => {
      const userTaskType = currentUser.value.taskType;
      let taskType = userTaskType
      // if(isEdit.value){
      //   taskType = currentTaskVisibleType.value
      // } else{
      //   taskType = userTaskType
      // }

      const showConfig = {
        rental: false,
        jobCost: false,
        unBilled: false
      }
      if (taskType === TASK_TYPES_VALUE.All) {
        showConfig.rental = true;
        showConfig.jobCost = true;
        showConfig.unBilled = true;
      } else if (taskType === TASK_TYPES_VALUE.Rental) {
        showConfig.rental = true;
      } else if (taskType === TASK_TYPES_VALUE.JobCost) {
        showConfig.jobCost = true;
        showConfig.unBilled = true;
      }
      return showConfig
    })

    const defaultTaskHeaderType = computed(() => {
      if (taskTypeDisplay.value.rental) {
        return TASK_TYPES.Rental
      }
      return TASK_TYPES.JobCost
    })

    const allowUpdate = ref<boolean>(true);
    const removingTaskId = ref<string | undefined>('');

    const datePickerStartDate = ref<any>(null);
    const selectTaskChangeType = ref<any>({
      taskType: "",
      taskId: "",
    });
    const displayButtonAddTask = ref<boolean>(true);
    onBeforeMount(async () => {
      if (!props.isEdit) {
        // Create timesheet
        initTimesheetLine();
      }
    });

    const timesheetLineOptionInitData = {
      isExpend: true,
      type: defaultTaskHeaderType.value,
      hours: 0,
      mins: 0,
      visiblePopover: false,
      isValid: false,
      startTime: {
        hours: 12,
        isAm: true,
        minutes: 0,
        isInit: true
      },
      endTime: {
        hours: 12,
        isAm: true,
        minutes: 0,
        isInit: true
      }
    }

    const initTimesheetLine = () => {
      timeSheetHeader.value.TimesheetLines?.push({
        TaskUuid: uuidv4(),
        Option: {
          ...timesheetLineOptionInitData,
          name: "Task 1"
        },
      });
      timeSheetHeader.value.StartDate = dayjs().format("YYYY-MM-DD");
      timeSheetHeader.value.Shift = false
      if (currentUser.value.defaultStartTime && timeSheetHeader?.value?.TimesheetLines?.[0]?.Option?.startTime) {
        const timeObj = formatTime(currentUser.value.defaultStartTime)
        timeSheetHeader.value.TimesheetLines[0].StartTime = timeObj.hours * 60 + timeObj.minutes
        timeSheetHeader.value.TimesheetLines[0].Option.startTime = {
          ...timeObj,
        }
      }
    };

    const handelExpendTask = (taskId?: string) => {
      const task = timeSheetHeader.value.TimesheetLines?.find(
        (x) => x.TaskUuid === taskId
      );
      if (task) {
        task.Option!.isExpend = !task.Option?.isExpend;
      }
    };
    const handleSelectTabTask = () => {
      const task = timeSheetHeader.value.TimesheetLines?.find(
        (x) => x.TaskUuid === selectTaskChangeType.value.taskId
      );
      timeSheetHeader.value.TimesheetLines?.map((x: any) => {
        if (x.TaskUuid === selectTaskChangeType.value.taskId) {
          x.SiteId = undefined
          x.EquipmentId = undefined
          x.WorkOrderId = undefined
          x.WorkOrderTask = {}
          x.WorkOrderTaskId = undefined
          x.WorkOrderDescription = ''
          x.JobCostCodeId = undefined
          x.JobId = undefined
          x.JobCostCode = {}
          x.Glaccount = {}
          x.GlaccountId = undefined
          x.TimesheetAdditional = null as any
          x.Notes = ''
          x.Option.hours = 0
          x.Option.mins = 0
          x.Option.isValid = false
          x.EndTime = 0
          x.TimesheetLineMinutes = 0
          if (x.Option.endTime) {
            x.Option.endTime.isInit = true;
            x.Option.endTime.isAm = true;
            x.Option.endTime.hours = 12;
            x.Option.endTime.minutes = 0;
          }
          if (x.Option.name != "Task 1") { 
            x.StartTime = 0
            if (x.Option.startTime) {
              x.Option.startTime.isInit = true;
              x.Option.startTime.isAm = true;
              x.Option.startTime.hours = 12;
              x.Option.startTime.minutes = 0;
            }
          }
        }
        return x
      })
      if (task) {
        task.Option!.type = selectTaskChangeType.value.taskType;
        isTouched.value[selectTaskChangeType.value.taskId] = false
        isTouchedSwitch.value = false
      }
      calTotalTimeSheet();
      const isOverlap = funcOverLap()
      if (isOverlap) {
        ctx.emit('handlePopupOverLap', true)
      } else {
        ctx.emit('handlePopupOverLap', false)
      }
      popupConfirmChangeType.value.showDialog = false;
      ctx.emit('changeTouched', false)
      ctx.emit('handleCancelSubmit', false)
    };

    const funcFindMaxMinTime = () => {
      const arrayList = [...timeSheetHeader?.value?.TimesheetLines];
      const errors = [];
      const convertList = arrayList.map((item: any) => {
        if (item.StartTime === 1440) {
          return ({ ...item, StartTime: 0})
        }
        return item
      })

      convertList.sort((taskA: any, taskB: any) => taskA.StartTime - taskB.StartTime);

      for (const shift of convertList) {
        if (!shift.Option.startTime.isInit && !shift.Option.endTime.isInit) {
          if (timeSheetHeader.value.Shift) {
            if (!(shift.StartTime >= 1080 || shift.StartTime <= 360) || !(shift.EndTime >= 1080 || shift.EndTime <= 360)) {
              errors.push({ dayShift: true });
            }
          } else {
            if (!(shift.StartTime >= 360 && shift.StartTime <= 1080) || !(shift.EndTime >= 360 && shift.EndTime <= 1080)) {
              errors.push({ nightShift: true });
            }
          }
        }
      }
      return errors;
    }

    const calTotalTimeSheet = () => {
      let totalMinuets = 0;
      timeSheetHeader.value.TimesheetLines?.forEach((item) => {
        totalMinuets += item.TimesheetLineMinutes ?? 0;
      });
      totalHours.value = Math.floor(totalMinuets / 60);
      totalMins.value = totalMinuets % 60;
      ctx.emit('changeMaxTimeMultipleTasks', totalMinuets)
      const shiftCheck: any = funcFindMaxMinTime()
      if (shiftCheck.length > 0) {
        if (shiftCheck[0].dayShift) {
          ctx.emit('handleCheckDayShift', true)
        } else {
          ctx.emit('handleCheckDayShift', false)
        }
        if (shiftCheck[0].nightShift) {
          ctx.emit('handleCheckNightShift', true)
        } else {
          ctx.emit('handleCheckNightShift', false)
        }
      } else {
        ctx.emit('handleCheckDayShift', false)
        ctx.emit('handleCheckNightShift', false)
      }
    };

    const handleOpenPopupChangeTaskType = (
      taskType: string,
      taskId?: string
    ) => {
      const task = timeSheetHeader.value.TimesheetLines?.find(
        (x) => x.TaskUuid === taskId
      );
      if (task) {
        if (allowUpdate.value) {
          selectTaskChangeType.value.taskType = taskType;
          selectTaskChangeType.value.taskId = taskId;
          if (isTouched.value[taskId!]) {
            popupConfirmChangeType.value.showDialog = true;
          } else {
            handleSelectTabTask()
          }
        }
      }
    };

    const handleErrorSwitchTaskType = () => {
      popupError.value.showDialog = true
    }

    const addTask = () => {
      let maxEndTime = timeSheetHeader.value?.TimesheetLines.reduce((max, line) => (max?.EndTime ?? 0) > (line?.EndTime ?? 0) ? max : line, 0);
      const newTime = JSON.stringify(maxEndTime?.Option?.endTime)
      timeSheetHeader.value.TimesheetLines?.push({
        TaskUuid: uuidv4(),
        StartTime: (maxEndTime?.EndTime ?? 0),
        EndTime: (maxEndTime?.EndTime ?? 0),
        Option: {
          ...timesheetLineOptionInitData,
          name: `Task ${timeSheetHeader.value.TimesheetLines?.length + 1}`,
          startTime: JSON.parse(newTime) ?? timesheetLineOptionInitData.endTime,
        },
      });
      store.commit("dashboard/SET_IS_EDIT_ADD_TASK", true);
      
      cachingStartTime.value = JSON.parse(newTime) ?? timesheetLineOptionInitData.endTime
    };
    const removeTask = (taskId?: string) => {
      removingTaskId.value = taskId
      if (isTouched.value[taskId!]) {
        popupConfirmDelete.value.showDialog = true;
      } else {
        handleRemoveTask()
      }
    };
    const handleRemoveTask = () => {
      const indexTimeSheetLine =
        timeSheetHeader.value.TimesheetLines?.findIndex(
          (i) => i.TaskUuid == removingTaskId.value
        );
      if (indexTimeSheetLine !== undefined && indexTimeSheetLine > -1) {
        timeSheetHeader.value.TimesheetLines?.splice(indexTimeSheetLine, 1);
        removingTaskId.value = ''
        popupConfirmDelete.value.showDialog = false;
      }
      store.commit("dashboard/SET_IS_EDIT_ADD_TASK", false);
      calTotalTimeSheet()
      const isOverlap = funcOverLap()
      if (isOverlap) {
        ctx.emit('handlePopupOverLap', true)
      } else {
        ctx.emit('handlePopupOverLap', false)
      }
      ctx.emit('handleCancelSubmit', false)
    }
    const handleTaskSubmit = (data: any) => {
      if (messageValidTime.value) {
        store.commit("SET_LOADING", false);
      }
      const task = timeSheetHeader.value.TimesheetLines?.find(
        (x) => x.TaskUuid === data.taskId
      );
      
      if (task) {
        task.Option!.isValid = data.valid;
        
        if (!data.valid) {
          task.Option!.isExpend = true;
          return;
        }
        task.Notes = data.jobDescription;
        task.WorkOrderTaskId = undefined;
        task.JobCostCodeId = undefined;
        task.GlaccountId = undefined;
        if (task.Option!.type == TASK_TYPES.Rental.toString()) {
          task.TimesheetLineType = getTaskTypId(TASK_TYPES.Rental);
          task.WorkOrderTaskId = data.workOrderTaskId ? data.workOrderTaskId : undefined;
          task.WorkOrderId = data.workOrder ? data.workOrder : undefined;
          task.EquipmentId = data.fleet ? data.fleet : undefined;
          task.SiteId = parseInt(data?.site?.split('-')?.[1] ?? null)
          task.SiteId = task.SiteId ? task.SiteId : undefined;
        }
        if (task.Option!.type == TASK_TYPES.JobCost.toString()) {
          task.TimesheetLineType = getTaskTypId(TASK_TYPES.JobCost);
          task.JobCostCodeId = data.costCode ? data.costCode : undefined;
          task.JobId = data.job ? data.job : undefined;
          task.EmecoSite = data.isEmecoSite;
          if(data.isEmecoSite) {
            task.WorkOrderId = data.workOrder ? data.workOrder : undefined;
            task.EquipmentId = data.fleet ? data.fleet : undefined;
            task.SiteId = parseInt(data?.site?.split("-")?.[1] ?? null);
            task.SiteId = task.SiteId ? task.SiteId : undefined;
          }
        }
        if (task.Option!.type == TASK_TYPES.UnBilled.toString()) {
          task.TimesheetLineType = getTaskTypId(TASK_TYPES.UnBilled);
          task.GlaccountId = data.glEntry;
        }
        if (data.additionInfo) {
          task.TimesheetAdditional = {
            TimesheetAdditionalId: data.TimesheetAdditionalId,
            Manufacturer: data.manufacturer,
            Model: data.model,
            Smureading: data.SMUHour,
            SerialNumber: data.serialNo,
            CustomerName: data.isEmecoSite ? "Emeco" : data.clientName,
            CustomerEmail: data.customerEmail,
            Locations: data.location,
            TravelKms: data.travelKMS,
            Rego: data.vehRegNo,
            WorkerSignature: data.mechanicalAcceptance,
            CustomerSignature: data.customerAcceptance,
            AdditionalInfo: data.fieldServiceNote,
            CustomerAcceptanceName: data.customerAcceptanceName
          };
        } else {
          task.TimesheetAdditional = null as any
        }
      }
    };

    const handleSubmitTaskTime = (data: any) => {
      const task = timeSheetHeader.value.TimesheetLines?.find(
        (item) => item.TaskUuid === data.taskId
      );
      if (task) {
        const timesheetLineMinutes = data.hours * 60 + data.mins;
        task.TimesheetLineMinutes = timesheetLineMinutes;
        let startTimeHours = task?.Option?.startTime?.hours ?? 0;
        
        if (task?.Option?.startTime?.isAm) {
          if (task?.Option?.startTime?.hours == 12) {
            startTimeHours = 24
          } else {
            startTimeHours = task?.Option?.startTime?.hours ?? 0
          }
        }

        const startTimeMinutes = task?.Option?.startTime?.minutes ?? 0;
        const startTimeInMinutes = startTimeHours * 60 + startTimeMinutes;
        const endTimeInMinutes = startTimeInMinutes + timesheetLineMinutes;
        let endTimeFinalHour = Math.floor(endTimeInMinutes / 60);
        const endTimeFinalMinutes = endTimeInMinutes - endTimeFinalHour * 60;
        endTimeFinalHour = endTimeFinalHour == 24 ? 24 : endTimeFinalHour % 24;

        let time = {
          hours: Math.abs(endTimeFinalHour),
          minutes: Math.abs(endTimeFinalMinutes),
          isAm: endTimeFinalHour == 24 ? true : endTimeFinalHour < 12
        }
        const startInit = task?.Option?.startTime?.isInit 
        const endInit = task?.Option?.endTime?.isInit 
        
        // AA, AB, BA, BB
        if (startInit && endInit) {
          task.Option.startTime = {
            hours: 12,
            minutes: 0,
            isAm: true,
            isInit: false,
          }
          task.Option.endTime = {
            ...time,
            isInit: false,
          }
        } else if (startInit && !endInit) {
          let endTimeInMinutes = (task?.Option?.endTime?.hours ?? 0) * 60 + ( task?.Option?.endTime?.minutes ?? 0);
          let startTimeInMinutes = endTimeInMinutes - ((data.hours * 60) + data.mins );
          startTimeInMinutes = (startTimeInMinutes + 1440) % 1440;
          let hours = Math.floor(startTimeInMinutes / 60);
          let minutes = startTimeInMinutes % 60;
          let isAm = hours < 12;
          if (!isAm && hours !== 12) {
            hours -= 12;
          }
          task.Option.startTime = {
            hours: hours == 0 ? 12 : hours,
            minutes: minutes,
            isAm: isAm,
            isInit: false,
          }
        } else if (!startInit && endInit) {
          task.Option.endTime = {
            ...time,
            isInit: false,
          }
        } else if (!startInit && !endInit) {
          task.Option.endTime = {
            ...time,
            isInit: false,
          }
        }
        task.Option.hours = data.hours
        task.Option.mins = data.mins
        task.EndTime = (time.hours * 60 + time.minutes) > 1440 ? (time.hours * 60 + time.minutes) - 1440 : (time.hours * 60 + time.minutes);
        ctx.emit('changeEndTimePM', time.hours * 60 + time.minutes)
        calTotalTimeSheet();
        const isOverlap = funcOverLap()
        if (isOverlap) {
          ctx.emit('handlePopupOverLap', true)
        } else {
          ctx.emit('handlePopupOverLap', false)
        }
      }
    };

    const funcOverLap = () => {
      let hasOverlap = false;
      const timesheetLines = timeSheetHeader?.value?.TimesheetLines ?? [];
      if (timesheetLines.length) {
        const sortedTasks: any = [...timesheetLines].sort(
          (a: any, b: any) => a.StartTime - b.StartTime
        )

        for (let i = 0; i < sortedTasks.length - 1; i++) {
          for (let j = i + 1; j < sortedTasks.length; j++) {
            let isInitStart1 = false
            let isInitEnd1 = false
            let isInitStart2 = false
            let isInitEnd2 = false

            let start1 = (timesheetLines?.[i]?.Option?.startTime?.hours ?? 0) * 60 + (timesheetLines?.[i]?.Option?.startTime?.minutes ?? 0);
            if (timesheetLines?.[i]?.Option?.startTime?.hours == 12) {
              if (timesheetLines?.[i]?.Option?.startTime?.isAm) {
                if (timesheetLines?.[i]?.Option?.startTime?.minutes ?? 0 > 0) {
                  start1 = start1 - 720
                } else {
                  start1 = 0
                }
              }
            } 
            isInitStart1 = !!timesheetLines?.[i]?.Option?.startTime?.isInit

            let end1 = (timesheetLines?.[i]?.Option?.endTime?.hours ?? 0) * 60 + (timesheetLines?.[i]?.Option?.endTime?.minutes ?? 0);
            if (timesheetLines?.[i]?.Option?.endTime?.hours == 12) {
              if (timesheetLines?.[i]?.Option?.endTime?.isAm) { 
                if (timesheetLines?.[i]?.Option?.endTime?.minutes ?? 0 > 0) {
                  end1 = end1 - 720
                } else {
                  end1 = 0
                }
              } 
            }
            isInitEnd1 = !!timesheetLines?.[i]?.Option?.endTime?.isInit

            let start2 = (timesheetLines?.[j]?.Option?.startTime?.hours ?? 0) * 60 + (timesheetLines?.[j]?.Option?.startTime?.minutes ?? 0);
            if (timesheetLines?.[j]?.Option?.startTime?.hours == 12) {
              if (timesheetLines?.[j]?.Option?.startTime?.isAm) {
                if (timesheetLines?.[j]?.Option?.startTime?.minutes ?? 0 > 0) {
                  start2 = start2 - 720
                } else {
                  start2 = 0
                }
              }  
            }
            isInitStart2 = !!timesheetLines?.[j]?.Option?.startTime?.isInit
            
            let end2 = (timesheetLines?.[j]?.Option?.endTime?.hours ?? 0) * 60 + (timesheetLines?.[j]?.Option?.endTime?.minutes ?? 0);
            if (timesheetLines?.[j]?.Option?.endTime?.hours == 12) {
              if (timesheetLines?.[j]?.Option?.endTime?.isAm) {
                if (timesheetLines?.[j]?.Option?.endTime?.minutes ?? 0 > 0) {
                  end2 = end2 - 720
                } else {
                  end2 = 0  
                }
              }
            }
            isInitEnd2 = !!timesheetLines?.[j]?.Option?.endTime?.isInit

            const allIsInit = !isInitStart1 && !isInitEnd1 && !isInitStart2 && !isInitEnd2

            if (allIsInit) {
              if (start1 > end1) { 
                if ((start2 >= 0 && start2 < end1) || (end2 > start1 && end2 <= 24 * 60)) {
                  hasOverlap = true;
                  break;  
                }
              }

              if (start2 > end2) {
                if ((start1 >= 0 && start1 < end2) || (end1 > start2 && end1 <= 24 * 60)) {
                  hasOverlap = true;
                  break;
                }
              }

              if ((start1 < end2 && end1 > start2) || (start2 < end1 && end2 > start1) || (start1 == start2)) {
                hasOverlap = true;
                break;
              }
            }

          }
          if (hasOverlap) {
            break;
          }
        }
      }
      return hasOverlap
    };
    
    const updateTaskTime = (data: any, additionValues: { TaskUuid: string, isStartTime: boolean }) => {
      const timesheetLineMinutes = data.hours * 60 + data.minutes;
      if (additionValues.isStartTime) {
        cachingStartTime.value = data
        ctx.emit('changeStartTimeAM', timesheetLineMinutes)
      } else {
        ctx.emit('changeEndTimePM', timesheetLineMinutes)
      }
      const task = timeSheetHeader.value.TimesheetLines?.find(
        (item) => item.TaskUuid === additionValues.TaskUuid
      );
      if (task) {
        if (additionValues.isStartTime) {
          task.Option.startTime = {
            ...data,
            isInit: false,
          }
          task.StartTime = timesheetLineMinutes
        } else {
          task.Option.endTime = {
            ...data,
            isInit: false,
          }
          task.EndTime = timesheetLineMinutes
        }

        if (!task.Option.startTime?.isInit && !task.Option.endTime?.isInit){
          let startTimeHours = task?.Option?.startTime?.hours ?? 0;
          let endTimeHours = task?.Option?.endTime?.hours ?? 0;

          if (task?.Option?.startTime?.isAm) {
            if (task?.Option?.startTime?.hours == 12) {
              startTimeHours = 24
            } else {
              startTimeHours = task?.Option?.startTime?.hours ?? 0
            }
          }
          if (task?.Option?.endTime?.isAm) {
            if (task?.Option?.endTime?.hours == 12) {
              endTimeHours = 24
            } else {
              endTimeHours = task?.Option?.endTime?.hours ?? 0
            }
          }
          const startTimeMinutes = task?.Option?.startTime?.minutes ?? 0;
          const endTimeMinutes = task?.Option?.endTime?.minutes ?? 0;
          let totalMins = endTimeMinutes - startTimeMinutes;
          let totalHours = endTimeHours - startTimeHours - (totalMins < 0 ? 1 : 0);
          totalMins = totalMins < 0 ? 60 - Math.abs(totalMins) : totalMins;
          const totalStartTimeMinutes = startTimeMinutes + startTimeHours * 60;
          const totalEndTimeMinutes = endTimeMinutes + endTimeHours * 60;
          if (totalHours < 0) {
            totalHours += 24;
          }
          task.Option.hours = totalHours;
          task.Option.mins = Math.abs(totalMins);
          if (task?.Option?.startTime?.isAm) {
            if (task?.Option?.startTime?.hours >= 12 && task?.Option?.startTime?.minutes > 0) {
              task.StartTime = totalStartTimeMinutes - 1440;
            } else {
              task.StartTime = totalStartTimeMinutes;
            }
          } else {
            task.StartTime = totalStartTimeMinutes;
          }
          
          if (task?.Option?.endTime?.isAm) {
            if (task?.Option?.endTime?.hours >= 12 && task?.Option?.endTime?.minutes > 0) {
              task.EndTime = totalEndTimeMinutes - 1440;
            } else {
              task.EndTime = totalEndTimeMinutes;
            }
          } else {
            task.EndTime = totalEndTimeMinutes;
          }

          task.TimesheetLineMinutes = (totalHours * 60) + Math.abs(totalMins);

          calTotalTimeSheet();
          const isOverlap = funcOverLap()
          if (isOverlap) {
            ctx.emit('handlePopupOverLap', true)
          } else {
            ctx.emit('handlePopupOverLap', false)
          }
        } else {
          if (task?.Option?.startTime?.isAm && task?.Option?.startTime?.hours == 12) {
            if (task?.Option?.startTime?.minutes == 0) {
              task.StartTime = 1440
            } else {
              task.StartTime = (task?.Option?.startTime?.hours * 60 + task?.Option?.startTime?.minutes) - 720
            }
          }
        }
      }
    };

    const onChangeTouched = (value: any, taskId: string) => {
      isTouched.value[taskId] = value
      isTouchedSwitch.value = true
      ctx.emit('changeTouched', value)
    }

    const createTimeSheet = () => {
      store.commit("SET_LOADING", true);
      if (!timeSheetHeader.value?.TimesheetLines?.length) {
        store.commit("SET_LOADING", false);
        return;
      }
      timeSheetHeader.value.StartDate = dayjs(timeSheetHeader.value.StartDate).format("YYYY-MM-DD"),
      timeSheetHeader.value.TimesheetLines.map((timesheet) => {
        if (timesheet?.Option?.endTime?.isInit) {
          timesheet.EndTime = undefined
        }
        if (timesheet?.Option?.startTime?.isInit) {
          timesheet.StartTime = undefined
        }
      })
      store.commit("SET_LOADING", true);
      store
        .dispatch("dashboard/actCreateTimeSheet", timeSheetHeader.value)
        .then(() => {
          store.commit('auth/SET_IS_VALID', false);
          store.commit("dashboard/SET_LIST_TIME_SHEET", []);
          store.dispatch("dashboard/actLoadTimeSheet"),
            store.dispatch("dashboard/actCountTimeSheetStatus"),
            store.commit("SET_LOADING", false);

          notify("success", MESSAGE_CODES.MSG_SAVE_SUCCESS);
          store.commit("SET_OPEN_POPUP_ADD_TASK", false);
          timeSheetHeader.value = {
            StartDate: dayjs().format("YYYY-MM-DD"),
            TimesheetLines: [
              {
                TaskUuid: uuidv4(),
                Option: {
                  name: `Task 1`,
                  ...timesheetLineOptionInitData,
                },
              },
            ],
          };
          totalHours.value = 0;
          totalMins.value = 0;
        })
        .catch(() => {
          store.commit("SET_LOADING", false);
          notify("error", MESSAGE_CODES.ERR_SERVER);
        });
    };

    const loadTimeSheetEdit = async (timeSheetHeaderId: number) => {
      store.commit("SET_LOADING", true);
      const resultTimeSheetHeader = await store.dispatch(
        "dashboard/actGetTimeSheet",
        { id: timeSheetHeaderId }
      );
      timeSheetHeader.value = {
        ...resultTimeSheetHeader,
        StartDate: dayjs(resultTimeSheetHeader.StartDate).format("YYYY-MM-DD"),
        TimesheetLines: [],
      };
      store.dispatch("dashboard/loadCountTotalTimesheet", { date: dayjs(resultTimeSheetHeader.StartDate).format("YYYY-MM-DD"), userId: resultTimeSheetHeader.UserId})
      ctx.emit('changeShift', !!timeSheetHeader.value.Shift)

      timeSheetHeader.value.TimesheetStatusName =
        timeSheetStatusName[
        timeSheetHeader.value
          .TimesheetStatus! as keyof typeof timeSheetStatusName
        ];
      if ([1, 2, 3, 5].includes(timeSheetHeader.value.TimesheetStatus ?? 0)) {
        if (
          timeSheetHeader.value.TimesheetStatus == 1 &&
          (currentUser.value.userType == USER_TYPE_APPROVER ||
            currentUser.value.userType == USER_TYPE_SYSTEM_ADMINISTRATOR) &&
          (currentUser.value.userId !== timeSheetHeader.value.UserId)
        ) {
          allowUpdate.value = true;
          ctx.emit('changeAllowEdit', true)
        } else {
          allowUpdate.value = false;
          ctx.emit('changeAllowEdit', false)
        }
      } else {
        if (currentUser.value.userId !== timeSheetHeader.value.UserId) {
          allowUpdate.value = false;
          ctx.emit('changeAllowEdit', false)
        } else {
          allowUpdate.value = true;
          ctx.emit('changeAllowEdit', true)
        }
      }
      if (currentUser.value.userType == USER_TYPE_USER_ADMINISTRATOR) {
        allowUpdate.value = false;
        ctx.emit('changeAllowEdit', false)
      }
      let lineVisibleTypes: number[] = []
      if (resultTimeSheetHeader.TimesheetLines) {
        let totalMinEdit = 0;
        for (let index = 0; index < resultTimeSheetHeader.TimesheetLines.length; index++) {
          const line = resultTimeSheetHeader.TimesheetLines[index];

          let hourLine = 0;
          let minLine = 0;
          if (line.TimesheetLineMinutes) {
            minLine = line.TimesheetLineMinutes % 60;
            hourLine = Math.floor(line.TimesheetLineMinutes / 60);
            totalMinEdit += line.TimesheetLineMinutes;
          }
          let additionInfo = undefined;
          if (
            line.TimesheetAdditionals &&
            line.TimesheetAdditionals.length > 0
          ) {
            additionInfo = { ...line.TimesheetAdditionals[0] };
          }
          delete line["TimesheetAdditionals"];
          const timeSheetLine: TimeSheetLine = {
            ...line,
            TaskUuid: uuidv4(),
            Option: {
              isExpend: true,
              name: `Task ${index + 1}`,
              type: convertTaskIdToType(line.TimesheetLineType!),
              hours: hourLine,
              mins: minLine,
              visiblePopover: false,
              startTime: getHoursAndMinutes(line.StartTime, line.TimesheetLineMinutes),
              endTime: getHoursAndMinutes(line.EndTime, line.TimesheetLineMinutes),
              isValid: false,
            },
          };
          timeSheetLine.TimesheetAdditional = additionInfo;
          timeSheetHeader.value.TimesheetLines?.push(timeSheetLine);
          const lineVisibleType = convertTaskIdToVisbleType(line.TimesheetLineType!)
          if (lineVisibleType != null) {
            lineVisibleTypes = [...lineVisibleTypes, lineVisibleType] 
          }
          ctx.emit('changeStartTimeAM', line.TimesheetLineMinutes == 0 ? null : timeSheetLine.StartTime)
          ctx.emit('changeEndTimePM', line.TimesheetLineMinutes == 0 ? null : timeSheetLine.EndTime)

        }
        totalHours.value = Math.floor(totalMinEdit / 60);
        totalMins.value = totalMinEdit % 60;
      }

      if (
        currentUser.value.userType == USER_TYPE_APPROVER ||
        currentUser.value.userType == USER_TYPE_SYSTEM_ADMINISTRATOR
      ) {
        if (timeSheetHeader.value.UserId != currentUser.value.userId) {
          displayButtonAddTask.value = false;
        } else {
          if (
            [1, 2, 3, 5].includes(timeSheetHeader.value.TimesheetStatus ?? 0)
          ) {
            displayButtonAddTask.value = false;
          } else {
            displayButtonAddTask.value = true;
          }
        }
      } else {
        if ([1, 2, 3, 5].includes(timeSheetHeader.value.TimesheetStatus ?? 0)) {
          displayButtonAddTask.value = false;
        } else {
          displayButtonAddTask.value = true;
        }
      }
      calTotalTimeSheet();
      const taskVisibleType = lineVisibleTypes.includes(1) && lineVisibleTypes.includes(2) ? 0 : lineVisibleTypes[0]
      currentTaskVisibleType.value = taskVisibleType !== resultTimeSheetHeader?.User?.TaskType ? 0 :  taskVisibleType
      store.commit("SET_LOADING", false);
      oldData.value = {
        Id: timeSheetHeader.value.TimesheetHeaderId,
        StartDate: dayjs(timeSheetHeader.value.StartDate).format("YYYY-MM-DD"),
        TimesheetStatus: timeSheetHeader.value.TimesheetStatus,
        Shift: !!timeSheetHeader.value.Shift,
        TimesheetLines: [],
      };
      timeSheetHeader.value.TimesheetLines?.forEach((line: any) => {
        const updateLine = {
          TimesheetLineId: line.TimesheetLineId,
          TimesheetLineMinutes: line.TimesheetLineMinutes,
          TimesheetLineType: line.TimesheetLineType,
          WorkOrderTaskId: line.WorkOrderTaskId ? line.WorkOrderTaskId : null,
          EquipmentId: line.EquipmentId ? line.EquipmentId : null,
          SiteId: line.SiteId ? line.SiteId : null,
          WorkOrderId: line.WorkOrderId ? line.WorkOrderId : null,
          JobId: line.JobId ? line.JobId : null,
          GlaccountId: line.GlaccountId ? line.GlaccountId : null,
          JobCostCodeId: line.JobCostCodeId ? line.JobCostCodeId : null,
          StartTime: line.StartTime ?? 0,
          EndTime: line.EndTime ?? 0,
          Notes: line.Notes,
          TimesheetAdditional: line.TimesheetAdditional ? line.TimesheetAdditional : undefined,
          Option: line.Option,
        };
        oldData.value.TimesheetLines.push(updateLine);
      });
    };

    const updateTimeSheet = async (timeSheetStatus: number) => {
      store.commit("SET_LOADING", true);
      let result = false
      if (!timeSheetHeader.value.TimesheetLines?.length) {
        return false;
      }
      const dataUpdate: any = {
        Id: timeSheetHeader.value.TimesheetHeaderId,
        StartDate: dayjs(timeSheetHeader.value.StartDate).format("YYYY-MM-DD"),
        TimesheetStatus: timeSheetStatus,
        Shift: !!timeSheetHeader.value.Shift,
        TimesheetLines: [],
      };

      timeSheetHeader.value.TimesheetLines?.forEach((line) => {
        const updateLine = {
          TimesheetLineId: line.TimesheetLineId,
          TimesheetLineMinutes: line.TimesheetLineMinutes,
          TimesheetLineType: line.TimesheetLineType,
          WorkOrderTaskId: line.WorkOrderTaskId ? line.WorkOrderTaskId : null,
          EquipmentId: line.EquipmentId ? line.EquipmentId : null,
          SiteId: line.SiteId ? line.SiteId : null,
          WorkOrderId: line.WorkOrderId ? line.WorkOrderId : null,
          JobId: line.JobId ? line.JobId : null,
          GlaccountId: line.GlaccountId ? line.GlaccountId : null,
          JobCostCodeId: line.JobCostCodeId ? line.JobCostCodeId : null,
          StartTime: line.StartTime ?? 0,
          EndTime: line.EndTime ?? 0,
          Notes: line.Notes,
          TimesheetAdditional: line.TimesheetAdditional ? line.TimesheetAdditional : undefined,
          Option: line.Option,
        };
        dataUpdate.TimesheetLines.push(updateLine);
      });

      dataUpdate.TimesheetLines.map((timesheet: any) => {
        if (timesheet?.Option?.endTime?.isInit) {
          timesheet.EndTime = undefined
        }
        if (timesheet?.Option?.startTime?.isInit) {
          timesheet.StartTime = undefined
        }
      })

      const objBefore = JSON.stringify(oldData.value)
      const objAfter = JSON.stringify(dataUpdate)

      if (objBefore === objAfter) {
        store.commit("SET_LOADING", false);
        return true
      }

      await store.dispatch("dashboard/actUpdateTimeSheet", dataUpdate).then(() => {
        store.commit("SET_LOADING", false);
        store.commit('auth/SET_IS_VALID', false);
        result = true
      })
        .catch((error) => {
          console.log('error', error.data.value)
          store.commit("SET_LOADING", false);
          if (error.data.value !== "Sequence contains no elements") {
            notify("error", MESSAGE_CODES.ERR_SERVER);
          }
          result = false
        })
      return result;
    };

    const getStatusValidTimeSheet = () => {
      const findItemValidFail = timeSheetHeader.value.TimesheetLines?.find(
        (x) => {
         
          return x.Option?.isValid == false
        }
      );
      return findItemValidFail != undefined ? false : true;
    };

    const resetData = () => {
      timeSheetHeader.value = {
        TimesheetLines: [],
        StartDate: undefined,
        TimesheetStatus: 0,
      };
      if (!props.isEdit) {
        initTimesheetLine();
      }
      totalHours.value = 0;
      totalMins.value = 0;
    };

    ctx.expose({
      loadTimeSheetEdit,
      getStatusValidTimeSheet,
      updateTimeSheet,
      resetData,
    });

    const action = computed(() => store.getters["dashboard/action"]);
    watch([action, allTaskIsValid], (value) => {
      ctx.emit('handleAllTaskIsValid', allTaskIsValid.value);
      if (allTaskIsValid.value) {
        if (action.value == Actions.SAVE.toString()) {
          timeSheetHeader.value!.TimesheetStatus = 0;
          createTimeSheet();
        }
        if (allFieldIsValid.value) {
          if (action.value == Actions.CONFIRM.toString()) {
            timeSheetHeader.value!.TimesheetStatus = 1;
            createTimeSheet();
          }
        }
      } 
    })

    const datePickerfocus = () => {
      datePickerStartDate.value.handleOpen();
    };

    const onConfirmChangeShift = (type: string) => {
      if (allowUpdate.value) {
        if (isTouchedSwitch.value) {
          popupConfirmChangeShift.value.showDialog = true;
        } else {
          onChangeShift(type)
        }
      }
    }
   
    const onChangeShift = (type: string) => {
      if (type == 'Night') {
        ctx.emit('changeShift', true)
        timeSheetHeader.value.Shift = true  
      } else {
        ctx.emit('changeShift', false)
        timeSheetHeader.value.Shift = false  
      }
      popupConfirmChangeShift.value.showDialog = false;
      const shiftCheck: any = funcFindMaxMinTime()

      if (shiftCheck.length > 0) {
        if (shiftCheck[0].dayShift) {
          ctx.emit('handleCheckDayShift', true)
        } else {
          ctx.emit('handleCheckDayShift', false)
        }
        if (shiftCheck[0].nightShift) {
          ctx.emit('handleCheckNightShift', true)
        } else {
          ctx.emit('handleCheckNightShift', false)
        }
      } else {
        ctx.emit('handleCheckDayShift', false)
        ctx.emit('handleCheckNightShift', false)
      }
    }

    const onChangeValidTime = (messageError: string) => {
      messageValidTime.value = messageError
    }

    const handleCalendarChange = (value: any) => {
      const formatDate = dayjs(value).format("YYYY-MM-DD")
      store.dispatch("dashboard/loadCountTotalTimesheet", { date: formatDate})
    }

    return {
      bgColorTaskType,
      handleSelectTabTask,
      currentTheme,
      handelExpendTask,
      IconCalendar,
      addTask,
      removeTask,
      handleTaskSubmit,
      handleSubmitTaskTime,
      disabledDate,
      totalHours,
      totalMins,
      updateTaskTime,
      timeSheetHeader,
      isEdit,
      isTouched,
      loadTimeSheetEdit,
      allowUpdate,
      currentUser,
      popupConfirmChangeType,
      popupConfirmDelete,
      popupError,
      selectTaskChangeType,
      handleOpenPopupChangeTaskType,
      handleRemoveTask,
      displayButtonAddTask,
      datePickerfocus,
      datePickerStartDate,
      onChangeShift,
      onChangeTouched,
      overlapTime,
      taskTypeDisplay,
      errors,
      handleErrorSwitchTaskType,
      onChangeValidTime,
      onConfirmChangeShift,
      popupConfirmChangeShift,
      handleCalendarChange
    };
  },
});
</script>
<style lang="scss">
.job-type {
  height: 36px;
  padding: 8px 32px;
}

.el-form-item__label {
  color: #231F20;
}

.dark {
  .detail-task {
    span {
      color: white;
    }
    .task-type-left,
    .task-type-middle,
    .task-type-right {
      span {
        color: #8D9095;
      }
    }
    .task-type-selected {
      span {
        color: #fff;
      }
    }
  }

  .btn-add-task {
    &:focus {
      background: #ED1C2D;
    }
  }

  .btn-add-task.is-disabled {
    opacity: 0.5;
  }

  .el-form-item__label {
    color: white;
  }

  .el-button.btn-time-shift path {
    fill: white;
  }

  .el-button.btn-time-shift:disabled {
    color: #828282;
  }
}

.btn-add-task {
  width: 153px;
}

.btn-add-task {
  &:focus {
    background: #ED1C2D;
  }
}

.btn-add-task.is-disabled {
  background: #ED1C2D;
  opacity: 0.5;
}

.btn-shift {
  width: 130px;
  border: none;
}

.rounded-group-button {
  border-radius: 6px;
  overflow: hidden;
}

.el-dialog-popup {
  .el-dialog__footer {
    padding-top: 24px;
    padding-bottom: 32px;
  }

  .el-dialog .el-dialog__footer .el-button.delete {
    margin-left: 16px;
  }

  .el-dialog .el-dialog__body {
    margin-top: 32px;
  }

  .el-dialog .el-dialog__header {
    margin-bottom: 0px !important;
  }
}
</style>